.sl-container {
}
.sl-logo {
  float: left;
  width: 90px;
  margin: 10px;
}
.sl-text {
  float: left;
  margin-top: 20px;
}
#menu {
  background-color: rgb(0, 0, 0);
  position: fixed;
  width: 100%;
  height: 10vh;
  color: #ffffff;
  z-index: 6;
  /* height: 20px; */
  /* padding-left: 18px; */
}
#menu ul,
#menu li {
  list-style: none;
}
#menu ul {
  width: 100%;
}
#menu li {
  /* display: inline-block; */
  position: static;
  margin-right: 3%;
  z-index: 2;
  float: right;
  margin-top: 1.5%;
  background-color: transparent;
}
#menu a {
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  z-index: 2;
  color: #ffffff;
}
#menu li a:hover {
  color: white;
  background: transparent;
  text-decoration: underline;
}
#menu input {
  display: none;
  margin: 0;
  padding: 0;
  width: 10%;
  opacity: 0;
  height: 50px;
  cursor: pointer;
  float: right;
}
#menu label {
  display: none;
  line-height: 43px;
  text-align: center;
  position: absolute;
  left: 0px;
}
#menu a.dropdown-arrow:after {
  content: "\25BE";
  margin-left: 5px;
}
#menu label:before {
  font-size: 1.6em;
  content: "\2261";
  margin-left: 20px;
  float: right;
}
#menu ul.sub-menus {
  height: auto;
  overflow: hidden;
  width: 170px;
  background: transparent;
  position: absolute;
  display: none;
  z-index: 7;
}
#menu ul.sub-menus li {
  display: block;
  width: 100%;
  z-index: 2;
}
#menu ul.sub-menus a {
  color: #575757;
  font-size: 16px;
  z-index: 7;
}
#menu li:hover ul.sub-menus {
  display: block;
}
#menu ul.sub-menus a:hover {
  background: #ffffff;
  color: #000000;
}
@media screen and (max-width: 840px) {
  #menu ul.sub-menus {
    width: 100%;
    position: static;
    background-color: transparent;
  }
  #menu ul.sub-menus a {
    padding-left: 0;
  }
  .sl-container {
    float: left;
  }

  .connect-button {
    float: none;
  }
  #menu ul {
    background: black;
    position: absolute;
    top: 40px;
    right: 0;
    left: 0%;
    width: 100%;
    height: auto;
    display: none;
  }
  #menu li {
    display: block;
    float: right;
    width: 100%;
    padding: 10px;
    margin-top: 0px;
    background-color: black;
  }
  #menu input,
  #menu label {
    position: absolute;
    top: 10px;
    right: 4%;
    display: block;
    color: white;
    float: right;
  }
  #menu input {
    color: white;
    z-index: 2;
  }
  #menu input:checked + label {
    color: white;
  }
  #menu input:checked + label:before {
    content: "\00d7";
  }
  #menu input:checked ~ ul {
    display: block;
  }
}
.link {
  font-weight: 800;
  text-decoration: none;
}
