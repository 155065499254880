.connect-button {
  background: linear-gradient(90.02deg, #3fff47 16.37%, #3fffd1 133.93%);
  border-radius: 5px;
  cursor: pointer;
  border: 0px;
  padding: 10px;
  color: black;
  font-style: normal;
  font-weight: 400;
  font-size: 15.2222px;
  float: right;
  margin-right: 10%;
  margin-top: 1%;
}
@media screen and (max-width: 840px) {
  .connect-button {
    float: none;
  }
}
