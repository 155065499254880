.mint-button {
  background: linear-gradient(90.02deg, #3fff47 16.37%, #3fffd1 133.93%);

  border-radius: 5px;
  width: 90%;
  padding: 10px;
  border: 0px;
  font-size: 25px;
  color: black;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 50px;
}
