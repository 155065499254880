.mint-container {
  width: 80%;
  height: auto;
  margin-left: 10%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(168, 168, 168, 0) 100%
  );
  border: 3px solid;
  border-image: linear-gradient(to right, #3fff47, #3ef7d2) 1;
}
.mint-image {
  width: 175px;
  margin: 6% 0%;
  border-radius: 50%;
}
.value {
  color: white;
  display: inline-block;
  padding: 5px;
  margin-left: 30%;
  margin-right: 30%;
}
.mint-value {
  width: 90%;
  border-radius: 5px;
  margin-top: 40px;
}

.current-price-text {
  float: left;
  margin-left: 7%;
  margin-top: 50px;
  font-size: 90%;
}
@media screen and (max-width: 840px) {
  .mint-container {
    width: 90%;
    margin-left: 5%;
  }
}
